import React, { useState } from "react";

const Disclaimer = ({ onDisclaimerSuccess }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onDisclaimerSuccess(); // Call this to navigate to ExcelTable
  };


  return (
    <div style={styles.container}>
      <h2>Disclaimer.....</h2>
      
     
          <p>
This is a tool, made for a close group for study purposes only. 

We are not doing any kind of recommendation for any purpose 
</p><p>
We are not using any kind of live data, all rates are dummy and based on this, one should not be trade in live market.
</p><p>
I understand completely, the Risk associated with live trading and am doing it at my own risk, I will not have a complaint with anyone.
</p>
        
<form onSubmit={handleSubmit}>
        <button type="submit" style={styles.button}>Ok</button>
      </form>
   
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '50vh',
    maxWidth: '400px',
    margin: '100px auto', // Added top and bottom margins
    padding: '20px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    borderRadius: '8px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  input: {
    padding: '10px',
    fontSize: '18px', // Increased font size for input fields
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  p: {
    padding: '10px',
    fontSize: '40px', // Increased font size for paragraph
  },
  button: {
    padding: '10px 15px',
    fontSize: '18px', // Increased font size for button
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default Disclaimer;




//import axios from 'axios';

// const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//         const response = await axios.post('YOUR_API_ENDPOINT', credentials);
//         // Handle successful response
//         onLoginSuccess();
//     } catch (error) {
//         // Handle error response
//         setError("Invalid email or password");
//     }
// };
