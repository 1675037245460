import React, { useState } from "react";
import axios from 'axios'; // Import axios for making HTTP requests

const Login = ({ onLoginSuccess, toggleForm ,toggleForgotPassword}) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make API call to your login endpoint
    //////////////////////////////////// http://www.sharesoft.co.in/:8000
    const response = await axios.post('/api/login', credentials);

      // Handle successful login response
      onLoginSuccess();
    } catch (error) {
      // Handle error response from the server 
      setError("Invalid user name or password");
    }
  };

  return (
    <div style={styles.container}>
      <h2>Login Form</h2>
     
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputGroup}>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label>Password:</label>
          <input
            type="password"
            name="password"
            value={credentials.password}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.button}>Login</button>
      </form>
      <p>
        New user? <button onClick={toggleForm} style={{ color: '#4CAF50', border: 'none', background: 'none', cursor: 'pointer' }}>Register here</button>
      </p>
      <p>
        Forgot your password? 
        <button onClick={toggleForgotPassword} style={{ color: '#4CAF50', border: 'none', background: 'none', cursor: 'pointer' }}>Click here</button>
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '50vh',
    maxWidth: '400px',
    margin: '100px auto', // Added top and bottom margins
    padding: '20px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    borderRadius: '8px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 15px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default Login;
