import React, { useState } from "react";
import Register from "./Registration"; // Ensure the path is correct
import Login from "./Login"; // Ensure the path is correct
import ExcelTable from "./ExcelTable"; // Import your ExcelTable component
import Disclaimer from "./Disclaimer";
import ResetPassword from "./ForgotPassword"; // You will create this component for the reset password form

const App = () => {
  const [currentView, setCurrentView] = useState("login"); // Start with the login view
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track if user is authenticated

  const handleLoginSuccess = () => {
    setIsAuthenticated(true); // Set authentication state to true
    setCurrentView("disclaimer"); // Navigate to the disclaimer page
  };

  const handleDisclaimerSuccess = () => {
    setCurrentView("excelTable"); // Navigate to ExcelTable after accepting the disclaimer
  };

  const handleRegisterSuccess = () => {
    setCurrentView("login"); // Navigate to the login form upon successful registration
  };

  const handleForgotPassword = () => {
    setCurrentView("resetPassword"); // Navigate to the reset password form
  };

  const handleResetPasswordSuccess = () => {
    setCurrentView("login"); // After successful password reset, return to the login form
  };

  return (
    <div>
      {currentView === "login" && (
        <Login 
          onLoginSuccess={handleLoginSuccess} 
          toggleForm={() => setCurrentView("register")} // Navigate to registration form
          toggleForgotPassword={handleForgotPassword} // Handle forgot password link click
        />
      )}
      {currentView === "register" && (
        <Register onRegisterSuccess={handleRegisterSuccess} />
      )}
      {currentView === "disclaimer" && (
        <Disclaimer 
          onDisclaimerSuccess={handleDisclaimerSuccess} 
        />
      )}
      {currentView === "excelTable" && (
        <ExcelTable /> // Render ExcelTable component
      )}
      {currentView === "resetPassword" && (
        <ResetPassword onResetPasswordSuccess={handleResetPasswordSuccess} />
      )}
    </div>
  );
};

export default App;
